export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'roles',
    label: 'field.role',
    rules: 'required',
    type: 'async-multi-selection',
    repository: 'role',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
